define("shared/components/gke-node-pool-row/component", ["exports", "semver", "shared/utils/util", "shared/components/gke-node-pool-row/template"], function (_exports, _semver, _util, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { coerceVersion } from 'shared/utils/parse-version';
  var _default = Ember.Component.extend({
    google: Ember.inject.service(),
    serviceVersions: Ember.inject.service('version-choices'),
    layout: _template.default,
    cluster: null,
    originalCluster: null,
    nodePool: null,
    nodeAdvanced: false,
    oauthScopesSelection: null,
    scopeConfig: null,
    diskTypeContent: null,
    imageTypeContent: null,
    machineTypes: null,
    nodeVersions: null,
    controlPlaneVersion: null,
    upgradeVersion: false,

    init() {
      this._super(...arguments);

      const {
        nodePool,
        maxVersion
      } = this;
      Ember.setProperties(this, {
        scopeConfig: {},
        diskTypeContent: this.google.diskTypes,
        imageTypeContent: this.google.imageTypesV2
      });

      if (nodePool) {
        if (!Ember.get(this, 'oauthScopesSelection')) {
          const oauthScopes = Ember.get(nodePool.config, 'oauthScopes');
          const {
            oauthScopesSelection,
            scopeConfig
          } = this.google.unmapOauthScopes(oauthScopes);
          Ember.set(this, 'oauthScopesSelection', oauthScopesSelection);

          if (scopeConfig) {
            Ember.set(this, 'scopeConfig', scopeConfig);
          }
        }

        if (Ember.isEmpty(nodePool === null || nodePool === void 0 ? void 0 : nodePool.version) && !Ember.isEmpty(maxVersion)) {
          Ember.set(this, 'nodePool.version', maxVersion);
        }
      } else {
        Ember.setProperties(this, {
          oauthScopesSelection: this.google.oauthScopeOptions.DEFAULT,
          scopeConfig: this.google.defaultScopeConfig
        });
      }
    },

    actions: {
      setNodeLabels(section) {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        Ember.set(this, 'nodePool.config.labels', section);
      },

      updateScopes() {
        const oauthScopesSelection = Ember.get(this, 'oauthScopesSelection');
        const scopeConfig = Ember.get(this, 'scopeConfig');
        Ember.run.next(() => {
          Ember.set(this.nodePool.config, 'oauthScopes', this.google.mapOauthScopes(oauthScopesSelection, scopeConfig));
        });
      }

    },
    scopeSelectionsChanged: Ember.observer('oauthScopesSelection', function () {
      this.send('updateScopes');
    }),
    editingUpdateNodeVersion: Ember.observer('isNewNodePool', 'clusterVersionIsLessThanMax', 'controlPlaneVersion', function () {
      const {
        isNewNodePool,
        clusterVersionIsLessThanMax
      } = this;
      const clusterVersion = Ember.get(this, 'controlPlaneVersion');
      const nodeVersion = Ember.get(this, 'nodePool.version');

      if (isNewNodePool && clusterVersion !== nodeVersion && clusterVersionIsLessThanMax) {
        Ember.set(this, 'nodePool.version', clusterVersion);
      }
    }),
    autoscalingChanged: Ember.observer('nodePool.autoscaling.enabled', function () {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      const {
        nodePool: {
          autoscaling
        }
      } = this;

      if (autoscaling !== null && autoscaling !== void 0 && autoscaling.enabled) {
        Ember.setProperties(this, {
          'nodePool.autoscaling.minNodeCount': 1,
          'nodePool.autoscaling.maxNodeCount': 3
        });
      } else {
        Ember.run.next(this, () => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          if (!Ember.isEmpty(autoscaling === null || autoscaling === void 0 ? void 0 : autoscaling.minNodeCount)) {
            Ember.set(this, 'nodePool.autoscaling.minNodeCount', null);
          }

          if (!Ember.isEmpty(autoscaling === null || autoscaling === void 0 ? void 0 : autoscaling.maxNodeCount)) {
            Ember.set(this, 'nodePool.autoscaling.maxNodeCount', null);
          }
        });
      }
    }),
    scopeConfigChanged: Ember.on('init', Ember.observer('scopeConfig', function () {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      Ember.set(this.nodePool.config, 'oauthScopes', this.google.mapOauthScopes(this.oauthScopesSelection, this.scopeConfig));
    })),
    regionalTotalNodeCounts: Ember.computed('locationType', 'nodePool.initialNodeCount', 'locationContent.@each.checked', function () {
      var _this$nodePool;

      const {
        locationType
      } = this;
      let totalLocations = this.locationContent.filterBy('checked').length;

      if (locationType === 'zonal') {
        // willSave in the cluster will add the selected zone as the default location in the locations array
        totalLocations = totalLocations + 1;
      }

      return (this === null || this === void 0 ? void 0 : (_this$nodePool = this.nodePool) === null || _this$nodePool === void 0 ? void 0 : _this$nodePool.initialNodeCount) * totalLocations;
    }),
    showManagementWarning: Ember.computed('originalCluster.gkeStatus.upstreamSpec.imported', 'nodePool.management.{autoUpgrade,autoRepair}', function () {
      var _originalCluster$gkeS, _originalCluster$gkeS2, _nodePool$management, _nodePool$management2;

      const {
        nodePool,
        originalCluster
      } = this;
      const isClusterImported = !Ember.isEmpty(originalCluster) && (originalCluster === null || originalCluster === void 0 ? void 0 : (_originalCluster$gkeS = originalCluster.gkeStatus) === null || _originalCluster$gkeS === void 0 ? void 0 : (_originalCluster$gkeS2 = _originalCluster$gkeS.upstreamSpec) === null || _originalCluster$gkeS2 === void 0 ? void 0 : _originalCluster$gkeS2.imported);

      if (isClusterImported && (!(nodePool !== null && nodePool !== void 0 && (_nodePool$management = nodePool.management) !== null && _nodePool$management !== void 0 && _nodePool$management.autoRepair) || !(nodePool !== null && nodePool !== void 0 && (_nodePool$management2 = nodePool.management) !== null && _nodePool$management2 !== void 0 && _nodePool$management2.autoUpgrade))) {
        return true;
      }

      return false;
    }),
    originalClusterVersion: Ember.computed('originalCluster.gkeConfig.kubernetesVersion', 'originalCluster.gkeStatus.upstreamSpec.kubernetesVersion', function () {
      if (!Ember.isEmpty(Ember.get(this, 'originalCluster.gkeConfig.kubernetesVersion'))) {
        return Ember.get(this, 'originalCluster.gkeConfig.kubernetesVersion');
      }

      if (!Ember.isEmpty(Ember.get(this, 'originalCluster.gkeStatus.upstreamSpec.kubernetesVersion'))) {
        return Ember.get(this, 'originalCluster.gkeStatus.upstreamSpec.kubernetesVersion');
      }

      return '';
    }),
    maxVersion: Ember.computed('versionChoices', 'controlPlaneVersion', function () {
      var _versionChoices$;

      const clusterVersion = Ember.get(this, 'controlPlaneVersion');
      const versionChoices = Ember.get(this, 'versionChoices');
      return (versionChoices === null || versionChoices === void 0 ? void 0 : (_versionChoices$ = versionChoices[0]) === null || _versionChoices$ === void 0 ? void 0 : _versionChoices$.value) || clusterVersion;
    }),
    clusterVersionIsLessThanMax: Ember.computed('maxVersion', 'controlPlaneVersion', function () {
      const clusterVersion = Ember.get(this, 'controlPlaneVersion');
      const maxVersion = Ember.get(this, 'maxVersion');
      return _semver.default.lte(clusterVersion, maxVersion, {
        includePrerelease: true
      });
    }),
    upgradeAvailable: Ember.computed('controlPlaneVersion', 'clusterVersionIsLessThanMax', 'mode', 'nodePool.version', 'originalClusterVersion', function () {
      const clusterVersion = Ember.get(this, 'controlPlaneVersion');
      const nodeVersion = Ember.get(this, 'nodePool.version');
      const clusterVersionIsLessThanMax = Ember.get(this, 'clusterVersionIsLessThanMax');

      if (Ember.isEmpty(clusterVersion) || Ember.isEmpty(nodeVersion)) {
        return false;
      }

      const nodeIsLess = _semver.default.lt(nodeVersion, clusterVersion, {
        includePrerelease: true
      });

      if (nodeIsLess && clusterVersionIsLessThanMax) {
        return true;
      }

      return false;
    }),
    isNewNodePool: Ember.computed('nodePool.isNew', function () {
      var _this$nodePool2;

      return this !== null && this !== void 0 && (_this$nodePool2 = this.nodePool) !== null && _this$nodePool2 !== void 0 && _this$nodePool2.isNew ? true : false;
    }),
    editedMachineChoice: Ember.computed('nodePool.config.machineType', 'machineChoices', function () {
      return Ember.get(this, 'machineChoices').findBy('name', Ember.get(this, 'nodePool.config.machineType'));
    }),
    machineChoices: Ember.computed('machineTypes.[]', function () {
      let out = (Ember.get(this, 'machineTypes') || []).slice();
      out.forEach(obj => {
        Ember.setProperties(obj, {
          displayName: `${obj.name} (${obj.description})`,
          group: obj.name.split('-')[0],
          sortName: (0, _util.sortableNumericSuffix)(obj.name)
        });
      });
      return out.sortBy('sortName');
    }),
    // versionChoices: computed('nodeVersions.[]', 'controlPlaneVersion', 'mode', function() {
    //   // google gke console allows the node version to be anything less than master version
    //   const {
    //     nodeVersions,
    //     controlPlaneVersion,
    //     mode,
    //   } = this;
    //   const coerceedVersion = coerceVersion(controlPlaneVersion);
    //   const maxVersionRange = `<= ${ coerceedVersion }`;
    //   let newVersions = this.serviceVersions.parseCloudProviderVersionChoices(nodeVersions, controlPlaneVersion, mode, maxVersionRange);
    //   const controlPlaneVersionMatch = newVersions.findBy('value', controlPlaneVersion);
    //   if (!isEmpty(controlPlaneVersionMatch)) {
    //     set(controlPlaneVersionMatch, 'label', `${ controlPlaneVersionMatch.label } (control plane version)`);
    //     set(this, 'nodePool.version', controlPlaneVersionMatch.value);
    //     const indexOfMatch = newVersions.indexOf(controlPlaneVersionMatch);
    //     if (indexOfMatch > 0) {
    //       // gke returns a semver like 1.17.17-gke.2800, 1.17.17-gke.3000
    //       // semver logic sorts these correctly but because we have to coerce the version, all versions in the 1.17.17 comebace
    //       // since they are sorted lets just find our CP master match index and cut everything off before that
    //       newVersions = newVersions.slice(indexOfMatch);
    //     }
    //   }
    //   return newVersions;
    // }),
    shouldUpgradeVersion: Ember.on('init', Ember.observer('upgradeVersion', 'clusterVersionIsLessThanMax', 'controlPlaneVersion', function () {
      const {
        upgradeVersion,
        clusterVersionIsLessThanMax
      } = this;
      const clusterVersion = Ember.get(this, 'controlPlaneVersion');
      const nodeVersion = Ember.get(this, 'nodePool.version');

      if (upgradeVersion && clusterVersion !== nodeVersion && clusterVersionIsLessThanMax) {
        Ember.set(this, 'nodePool.version', clusterVersion);
      }
    }))
  });

  _exports.default = _default;
});